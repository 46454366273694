import './App.css';
import TheHome from './pages/TheHome/TheHome';

function App() {
  return (
    <div className="App">
      <TheHome></TheHome>
    </div>
  );
}

export default App;
